<template>
  <div class="h-full">
    <div class="flex container text-center">
      <file-upload
        :ref="el => instance = el"
        class="w-full"
        :input-id="id"
        :name="name"
        :title="title"
        :multiple="mode === 'grid'"
        :drop="droppable"
        :accept="accept"
        :extensions="extensions"
        :size="maximumSize"
        :timeout="timeout"
        :maximum="maximumCount"
        :post-action="typeof action === 'string' ? action : undefined"
        :custom-acton="typeof action === 'string' ? undefined : action"
        :headers="headers"
        :data="parameters"
        v-model="files"
        @input-filter="filter"
        @input-file="input"
      >
        <div v-show="previewable" :class="`flex bg-transparent-pattern rounded${mode === 'grid' ? '-t' : ''}`">
          <img
            v-if="!loading && previewSrc"
            :class="`rounded${mode === 'grid' ? '-t' : ''} m-auto object-scale-down`"
            :src="previewSrc"
          />
          <img
            v-else-if="!loading && (defaultImage || (limitedWidth && limitedHeight))"
            :class="`rounded${mode === 'grid' ? '-t' : ''} m-auto object-scale-down`"
            :src="defaultImage || `https://via.placeholder.com/${limitedWidth}x${limitedHeight}.png`"
          />
          <FontAwesome v-else-if="!loading" class="m-auto h-48" icon="camera" type="fas" />
          <LoadingIcon v-else-if="loading" icon="oval" class="m-auto h-48" />
          <div v-show="!loading && previewSrc && mode === 'image' && files[0]?.success" class="absolute top-0 left-0 m-auto z-50">
            <FontAwesome
              icon="check-circle"
              type="fas"
              class="w-6 h-6 text-blue-500 p-1 rounded-tl rounded-br bg-white opacity-70 hover:opacity-100"
            />
          </div>
          <div v-if="!loading && previewSrc && mode === 'image'" class="absolute top-0 right-0 m-auto z-50">
            <button type="button" title="刪除" @click="reset()">
              <FontAwesome
                icon="trash-alt"
                type="fas"
                class="w-6 h-6 text-red-500 p-1 rounded-tr rounded-bl bg-white opacity-70 hover:opacity-100"
              />
            </button>
          </div>
        </div>
        <div v-if="mode !== 'input'" v-show="!previewSrc" class="text-xs font-semibold py-1 px-1 rounded-b text-white bg-gray-500 truncate">
          {{ droppable ? "點擊或拖曳檔案至此": "點擊此處選取檔案" }}
        </div>
        <div v-else>
          <div v-if="!previewSrc" class="text-gray-500 text-left p-2 bg-white border rounded truncate">
            {{ droppable ? "點擊或拖曳檔案至此": "點擊此處選取檔案" }}
          </div>
          <ul v-else class="container w-full border rounded" style="height: 32px">
            <li
              v-for="file in files"
              :key="file.id"
              class="text-center relative"
              @click="preview(file)"
            >
              <div :class="`w-full overflow-hidden h-1 text-xs flex rounded bg-gray-100`">
                <div
                  :style="{ width: `${file.progress}%` }"
                  :class="`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${ file.error ? 'red' : 'blue' }-400`"
                ></div>
              </div>
              <div class="flex w-full items-center justify-between bg-white pr-6 border rounded-b" style="height: 27px;">
                <span class="w-full pl-2 text-left truncate">{{ `${file.name}` }}</span>
                <span v-if="file.active" :class="`w-18 text-${ file.error ? 'red' : 'blue' }-400`">
                  {{ `${formatNumber(file.speed)}Bps` }}
                </span>
                <button v-else type="button" class="absolute right-3 z-50" @click.stop="removeFile(file)">
                  <i class="vxe-icon--close w-2 h-2 text-gray-300 hover:text-black" />
                </button>
              </div>
            </li>
          </ul>
        </div>
      </file-upload>
    </div>
    <ul class="container w-full" v-if="mode === 'grid' && files.length">
      <li
        v-for="file in files"
        :key="file.id"
        class="text-center relative"
        @click="preview(file)"
      >
        <div :class="`w-full overflow-hidden h-1 text-xs flex rounded bg-gray-100`">
          <div
            :style="{ width: `${file.progress}%` }"
            :class="`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-${file.error ? 'red' : 'blue'}-300`"
          ></div>
        </div>
        <div class="flex w-full items-center justify-between">
          <span v-if="file.active || file.success | file.error" :class="`w-6 text-xs font-semibold py-1 px-1 rounded-bl text-white bg-${file.error ? 'red' : 'blue'}-400`">
            <FontAwesome v-if="file.active" class="w-4 h-4" icon="cloud-upload-alt" />
            <FontAwesome v-else-if="file.success" class="w-4 h-4" icon="check-circle" />
            <FontAwesome v-else-if="file.error" class="w-4 h-4" icon="exclamation-triangle" />
          </span>
          <span class="w-full text-xs font-semibold pl-1 text-left truncate">{{ `${file.name}` }}</span>
          <span v-if="file.active" :class="`w-18 text-xs font-semibold text-${file.error ? 'red' : 'blue'}-400`">
            {{ `${formatNumber(file.speed)}Bps` }}
          </span>
          <button v-else type="button" @click.stop="removeFile(file)">
            <FontAwesome class="w-6 h-6 text-red-500" icon="minus-square" type="fas" />
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from '@cloudfun/core'
import FileUpload, { VueUploadItem } from 'vue-upload-component'
import Jimp from 'jimp'

export default defineComponent({
  components: {
    FileUpload
  },
  props: {
    id: String,
    name: String,
    title: String,
    droppable: { type: Boolean, default: true },
    accept: String,
    extensions: [Array, String, RegExp],
    previewable: { type: Boolean, default: true },
    limitedWidth: { type: Number, default: 0 },
    limitedHeight: { type: Number, default: 0 },
    maximumSize: { type: Number, default: 0 },
    timeout: { type: Number, default: 0 },
    maximumCount: Number,
    action: { type: [String, Promise] },
    headers: Object,
    parameters: Object,
    defaultImage: String,
    autoUpload: Boolean,
    mode: { type: String as PropType<'grid' | 'input' | 'image'>, default: 'grid' },
    modelValue: { type: [String, Array] as PropType<string | string[]> }
  },
  setup () {
    const instance = ref<any>({})
    const previewSrc = ref('')
    const files = ref<VueUploadItem[]>([])
    const value = ref<string | string[] | undefined>()

    return {
      instance,
      previewSrc,
      files,
      value,
      loading: ref(false),
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  watch: {
    modelValue (current) {
      if (current !== this.value) {
        if (current) {
          if (typeof current === 'string') {
            this.previewSrc = current
            this.files = [{ fileObject: false, id: this.instance.newId(), name: current }]
          } else if (Array.isArray(current)) {
            if (current.length) this.previewSrc = current[0]
            this.files = current.map((e: string) => { return { fileObject: false, id: this.instance.newId(), name: e } as VueUploadItem })
          }
        } else {
          this.previewSrc = ''
          this.files = []
        }
        this.value = this.computeValue()
        this.$emit('update:modelValue', this.value)
      }
    },
    files () {
      this.value = this.computeValue()
      this.$emit('update:modelValue', this.value)
    }
  },
  mounted () {
    if (this.modelValue) {
      if (typeof this.modelValue === 'string') {
        this.previewSrc = this.modelValue
        this.files = [{ fileObject: false, id: this.instance.newId(), name: this.modelValue }]
      } else if (Array.isArray(this.modelValue)) {
        if (this.modelValue.length) this.previewSrc = this.modelValue[0]
        this.files = this.modelValue.map(e => { return { fileObject: false, id: this.instance.newId(), name: e } as VueUploadItem })
      }
      this.value = this.computeValue()
    }
  },
  methods: {
    computeValue (): string | string[] | undefined {
      switch (this.mode) {
        case 'grid': return this.files.filter((e: VueUploadItem) => e.name).map((e: VueUploadItem) => { return e.response?.payload?.length ? e.response.payload[0] : e.name })
        default:
          if (!this.files.length) return undefined
          if (this.files[0]?.response?.payload?.length) return this.files[0].response.payload[0]
          return this.files[0]?.name
      }
    },
    formatNumber (number: number) {
      let count = 0
      while (number > 1000 && count < 9) {
        number /= 1000
        count++
      }
      let formatedNumber = '' + number.toFixed(2)
      if (formatedNumber.endsWith('.00')) formatedNumber = formatedNumber.substr(0, formatedNumber.length - 3)
      switch (count) {
        case 1: return `${formatedNumber}K`
        case 2: return `${formatedNumber}M`
        case 3: return `${formatedNumber}G`
        case 4: return `${formatedNumber}T`
        case 5: return `${formatedNumber}P`
        case 6: return `${formatedNumber}E`
        case 7: return `${formatedNumber}Z`
        case 8: return `${formatedNumber}Y`
        default: return `${formatedNumber}`
      }
    },
    filter (current: any, original: any, prevent: (prevent: boolean) => boolean) {
      this.$emit('filter', current, original, prevent)
    },
    input (current: any, original: any) {
      const URL = window.URL || window.webkitURL
      if (!URL || !URL.createObjectURL) return
      this.$emit('input', current, original)
      // revoke object url
      if (original?.file?.url && current?.file?.url !== original.file.url) URL.revokeObjectURL(original.file.url)
      // add file
      if (current && !original && current.fileObject) {
        let objectUrl = URL.createObjectURL(current.file)
        if (current?.file?.type && typeof current.file.type.startsWith && current.file.type.startsWith('image/')) {
          const image = new Image()
          image.src = objectUrl
          current.file.url = objectUrl
          this.$emit('load', current)
          this.loading = true
          image.onload = () => {
            this.previewSrc = this.defaultImage || `https://via.placeholder.com/${this.limitedWidth}x${this.limitedHeight}.png`
            if ((this.limitedWidth && image.width !== this.limitedWidth) || (this.limitedHeight && image.height !== this.limitedHeight)) {
              Jimp.read(URL.createObjectURL(current.file)).then((jimp) => {
                jimp.resize(this.limitedWidth || Jimp.AUTO, this.limitedHeight || Jimp.AUTO)
                jimp.getBufferAsync(jimp.getMIME()).then((buffer) => {
                  current.file = new Blob([buffer], { type: jimp.getMIME() })
                  objectUrl = URL.createObjectURL(current.file)
                  current.file.url = objectUrl
                  this.previewSrc = objectUrl
                  this.loading = false
                  this.$emit('loaded', current)
                })
              }).finally(() => {
                if (this.autoUpload) {
                  setTimeout(() => {
                    const file = this.files.find((e: any) => e.id === current.id)
                    if (file) { file.active = true; this.uploadFile(file) }
                  }, 500)
                }
              })
            } else {
              if (this.autoUpload) {
                setTimeout(() => {
                  const file = this.files.find((e: any) => e.id === current.id)
                  if (file) { file.active = true; this.uploadFile(file) }
                }, 500)
              }
              this.previewSrc = objectUrl
              this.loading = false
              this.$emit('loaded', current)
            }
          }
          image.onerror = (event) => {
            this.loading = false
            this.$emit('loadError', event)
          }
        }
      }
    },
    preview (file?: VueUploadItem) {
      this.previewSrc = (file?.file as any)?.url
    },
    removeFile (file: VueUploadItem) {
      this.instance.remove(file)
      this.files = this.files.filter((e: VueUploadItem) => e !== file)
      if (!this.files.length) this.preview(undefined)
      else if (this.previewSrc === (file.file as any)?.url) {
        this.preview(this.files[0])
      }
      this.value = this.computeValue()
      this.$emit('update:modelValue', this.value)
    },
    upload () {
      if (this.files.length) {
        const files = [...this.files]
        return Promise.all(files.filter((e) => e.active).map((file) => new Promise<VueUploadItem>((resolve, reject) => {
          this.instance.upload(file).then(
            (response: any) => {
              this.instance.update(file, { active: false, success: !file.error })
              response.active = false
              response.success = true
              this.value = this.computeValue()
              this.$emit('update:modelValue', this.value)
              this.$emit('uploaded', response)
              resolve(response)
            },
            (error: any) => {
              this.instance.update(file, {
                active: false,
                success: false,
                error: error.message || error.code || error.error || error
              })
              this.$emit('uploadError', file, error)
              reject(error)
            }
          )
        })))
      }
    },
    uploadFile(file: VueUploadItem) {
      return this.instance.upload(file).then(
        (response: any) => {
          this.instance.update(file, { active: false, success: !file.error })
          response.active = false
          response.success = true
          this.value = this.computeValue()
          this.$emit('update:modelValue', this.value)
          this.$emit('uploaded', response)
          Promise.resolve(response)
        },
        (error: any) => {
          this.instance.update(file, {
            active: false,
            success: false,
            error: error.message || error.code || error.error || error
          })
          this.$emit('uploadError', file, error)
          Promise.reject(error)
        }
      )
    },
    reset () {
      this.previewSrc = ''
      this.files = []
      this.value = this.computeValue()
      this.$emit('update:modelValue', this.value)
    }
  }
})
</script>
